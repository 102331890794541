<template>
    <a-layout style="min-height: 100vh">
        <a-layout-sider v-model:collapsed="collapsed" :collapsible="false" :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
            <div class="logo cursor-pointer" @click="goTo('/')">
                <img src="assets/images/logo.png" alt="logo" style="height: 32px; margin: 0 auto;"/>
            </div>
            <a-menu theme="light" v-model:selectedKeys="selectedKeys" mode="inline">
                <a-menu-item :key="1" @click="goTo('queues')">
                    <span>Queues</span>
                </a-menu-item>

                <a-menu-item :key="2" @click="goTo('aht')">
                    <span>AHT</span>
                </a-menu-item>

                <a-menu-item :key="3" @click="goTo('alt-sku')" v-if="1===0">
                    <span>Alternative SKU</span>
                </a-menu-item>

                <a-menu-item :key="4" @click="goTo('logs')">
                    <span>Logs</span>
                </a-menu-item>

                <a-menu-item :key="5" @click="goTo('low-stock')">
                    <span>Low Stock</span>
                </a-menu-item>

                <a-menu-item :key="6" @click="goTo('overdue-po')">
                    <span>Overdue PO</span>
                </a-menu-item>

                <a-menu-item :key="7" @click="goTo('po-review')" v-if="1===0">
                    <span>PO Review</span>
                </a-menu-item>

                <!--<a-menu-item :key="8" @click="goTo('process-fab')">
                    <span>Process Fab</span>
                </a-menu-item>-->

                <a-menu-item :key="9" @click="goTo('sku-lookup')">
                    <span>SKU Lookup</span>
                </a-menu-item>

                <a-menu-item :key="10" @click="goTo('unfulfillable-orders')">
                    <span>Unfulfillable Orders</span>
                </a-menu-item>

                <a-menu-item :key="11" @click="goTo('preorder')">
                    <span>PreOrder</span>
                </a-menu-item>

                <a-menu-item :key="12" @click="goTo('fab')">
                    <span>Fab</span>
                </a-menu-item>

                <a-menu-item :key="13" @click="goToLink('https://darkside-developments.com/ws_parts_ordered/')">
                    <span>WS Parts Ordered</span>
                </a-menu-item>

                <a-menu-item :key="14" @click="goTo('courier-claims')">
                    <span>Courier Claims</span>
                </a-menu-item>

                <a-menu-item :key="15" @click="goTo('item-aht')">
                    <span>Item AHT</span>
                </a-menu-item>
            </a-menu>

            <div class="ant-layout-sider-trigger" style="width: 200px;" @click="logout">
                <a-avatar size="small" class="ml-auto" :src="user.image_path"></a-avatar>
                <span class="text-xs ml-3">{{user.nicename}}</span>
            </div>
        </a-layout-sider>
        <a-layout :style="{ marginLeft: '200px' }">
            <a-layout-content class="h-full" style="margin: 16px 16px">
                <div class="h-full" :style="{ padding: '24px'}">
                    <router-view/>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import {defineComponent, ref} from 'vue';
export default defineComponent({
    data() {
        return {
            collapsed: ref(false),
            selectedKeys: ref(['1']),
        };
    },
    created() {
        this.$watch(
            () => this.$route.params,
            () => {
                this.selectedKeys = [this.$route.meta.id]
            },
            { immediate: true }
        )
    },
    computed: {
        user() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        goTo(path) {
            this.$router.push(path);
        },
        goToLink(path) {
            window.open(path, "_blank");
        },
        logout() {
            const conf = confirm("Are you sure you want to log out?")
            if(conf) {
                if (window.location.hostname === "localhost") {
                    this.$cookies.remove('user', null, '.localhost');
                } else {
                    this.$cookies.remove('user', null, '.darkside-developments.com');
                }
                localStorage.removeItem('user');
                location.reload();
            }
        }
    }
});
</script>
<style lang="scss">
.logo {
    height: 32px;
    padding: 32px;
    width: 200px;
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(229, 231, 235, 1);
    z-index: 2;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: rgba(229, 231, 235, 1);
    }
}
.ant-layout-sider-children .ant-menu {
    padding-top: 64px;
    padding-bottom: 48px;
}
.ant-menu-inline .ant-menu-item {
    margin-top: 0;
}
.site-layout .site-layout-background {
    background: #fff;
}

.ant-layout-sider {
    background: #fff;
}
.ant-layout-sider-trigger {
    background: white;
    border-top: 1px solid rgba(229, 231, 235, 1);
    color: #333;
}
header.ant-layout-header {
    background: #fff;
}
</style>
